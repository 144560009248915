import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import {
  subCategoryDelete,
  subCategoryUpdate,
} from "../../../redux/subCategory/subCategorySlice";
import { Modal, ModalHeader } from "reactstrap";

const ListSubCategory = () => {
  const dispatch = useDispatch();

  const { subcategorytotal } = useSelector((store) => store.subCategories);
  const { categorytotal } = useSelector((store) => store.categoryAll);

  const [supercategory, setSupercategory] = useState("");
  const [supercategoryid, setSupercategoryid] = useState("");
  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [catbySupercat, setCatbySupercat] = useState([]);
  const [subcatbyCat, setSubcatbyCat] = useState([]);

  const [selectCategorys, setSelectCategory] = useState("");
  const [cancelmodel, setCancelModel] = useState(false);

  const supercat = [
    { id: "63d767855658519287238b63", name: "Supermart" },
    { id: "63d8da2bd405398b770b508f", name: "Non-veg" },
    { id: "63d8da4ad405398b770b50aa", name: "Vegetables & fruits" },
  ];

  const selectsupCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSupercategoryid(value);
    setSupercategory(text);
  };

  const selectCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCategoryid(value);
    setCategory(text);
  };

  useEffect(() => {
    const catbysupercat = categorytotal.filter(
      (cat) => cat.superCategoryId === supercategoryid
    );
    setCatbySupercat(catbysupercat);
  }, [supercategoryid, categorytotal]);

  useEffect(() => {
    if (categoryid === "") {
      setSubcatbyCat(subcategorytotal);
    } else {
      const subcatbycat = subcategorytotal.filter(
        (cat) => cat.category === categoryid
      );
      const slotFilter = [...subcatbycat].sort((a, b) =>
        a.name > b.name ? 1 : -1
      );

      setSubcatbyCat(slotFilter);
    }
  }, [categoryid, subcategorytotal]);

  const deleteClick = async (e, id) => {
    const data = await dispatch(subCategoryDelete(selectCategorys._id));
  };

  const cancelClick = (e, subcategory) => {
    setCancelModel(true);
    setSelectCategory(subcategory);
  };

  const statusChange = async (e, data) => {
    e.preventDefault();
    console.log(data);
    const value = e.target.checked;

    if (data) {
      const formData = {
        subcatid: data._id,
        status: value,
      };
      const updateCat = await dispatch(subCategoryUpdate(formData));
    }
  };
  const columns = [
    {
      field: "id",
      headerName: "S. No",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: "SUB-CATEGORY NAME",
      width: 200,
      align: "center",
      align: "center",
      headerAlign: "center",
    },
    // {
    //   field: "thumbnail",
    //   headerName: "IMAGE",
    //   width: 230,
    //   headerAlign: "center",

    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <img
    //           className="d-flex m-auto"
    //           src={params.row.thumbnail}
    //           width={100}
    //           alt="thumnail"
    //         />
    //       </>
    //     );
    //   },
    // },
    {
      field: "STATUS",
      width: 120,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex m-auto">
              <Col>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=" "
                  value={params.row.status}
                  checked={params.row.status}
                  onChange={(e) => statusChange(e, params.row)}
                />
              </Col>
            </div>
          </>
        );
      },
    },

    {
      field: "priority",
      headerName: "PRIORITY",
      width: 150,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "ACTION",
      width: 200,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto">
            <Link to={`/admin/updatesubcategory/${params.row.slugUrl}`}>
              <Button variant="contained" color="primary">
                EDIT
              </Button>
            </Link>
          </div>
        );
      },
    },
    {
      field: "ACTION.",
      width: 200,
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <div className="d-flex m-auto">
            <Button
              variant="contained"
              onClick={(e) => cancelClick(e, cellValues.row)}
              color="error"
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Fragment>
        <div className="content-wrapper ">
          <div className="card me-2 ms-2">
            <div className="card-body">
              <Row className="g-2  m-2">
                <Col md>
                  <Form.Label htmlFor="disabledTextInput">
                    Super Category
                  </Form.Label>
                  <Form.Select
                    aria-label="Floating label select example"
                    onChange={(e) => {
                      selectsupCategory(e);
                    }}
                    value={supercat.id}
                    name={supercat.name}
                  >
                    <option value="0">{"Select Super Category"}</option>
                    {supercat.map((data) => (
                      <option
                        key={data.id}
                        value={data.id}
                        name={data.name}
                        required
                      >
                        {data.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col md>
                  <Form.Label htmlFor="disabledTextInput">Category</Form.Label>
                  <Form.Select
                    aria-label="Floating label select example"
                    onChange={(e) => {
                      selectCategory(e);
                    }}
                    value={catbySupercat._id}
                    name={catbySupercat.name}
                  >
                    <option value="0">{"Select Category"}</option>
                    {catbySupercat.map((data) => (
                      <option
                        key={data.id}
                        value={data.id}
                        name={data.name}
                        required
                      >
                        {data.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
            </div>
            <div
              style={{ height: 650, width: "100%" }}
              className="d-flex m-auto text-align-center"
            >
              <DataGrid
                rows={subcatbyCat}
                columns={columns}
                getRowId={(row) => row._id}
                pageSize={10}
                rowsPerPageOptions={[10]}
                // checkboxSelection
              />
            </div>
          </div>

          <Modal
            size="md"
            isOpen={cancelmodel}
            toggle={() => setCancelModel(!cancelmodel)}
          >
            <ModalHeader>
              <div className=" ">
                <div className="col-12 ">
                  <div className="col-12">
                    <h3>
                      Do You Want to{" "}
                      <span style={{ color: "red" }}>Delete</span>{" "}
                      {selectCategorys !== "" ? (
                        <>
                          <span
                            style={{
                              color: "#dc3545",
                            }}
                          >
                            {selectCategorys.name}
                          </span>
                        </>
                      ) : (
                        <></>
                      )}{" "}
                      Sub-Category
                    </h3>
                    <div className="col-12 mt-3 ">
                      <div className="col-6 d-flex justify-content-between">
                        <Button
                          variant="contained"
                          color="error"
                          className="mx-1 px-5"
                          onClick={() => setCancelModel(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          className="mx-1 px-5"
                          onClick={(e) => deleteClick(e)}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalHeader>
          </Modal>
        </div>
      </Fragment>
    </>
  );
};

export default ListSubCategory;
