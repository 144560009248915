import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
    subscriptiontotal: localStorage.getItem("subscriptiontotal")
      ? JSON.parse(localStorage.getItem("subscriptiontotal"))
      : [],
    mobileimage: "",
    desktopimage: "",
    issubscriptionmobileimageLoading: true,
    issubscriptiondeskimageLoading: true,
    isLoading: true,
    subscriptionLoading: true,
    delsubscriptionLoading: true,
  };

  export const getSubscription = createAsyncThunk(
    "Subscription/getSubscription",
    async (thunkAPI) => {
      try {
        const url = `${Baseurl}/api/v1/subscription/all`;
        const resp = await axios(url);
        return resp.data.subscription;
      } catch (error) {
        return thunkAPI.rejectWithValue("404 Not Found");
      }
    }
  );

  export const subscriptionPost = createAsyncThunk(
    "subscription/subscriptionPost",
    async (formData, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
        };
        const url = `${Baseurl}/api/v1/subscription/new`;
        const resp = await axios.post(url, formData, config);
        console.log(resp.data);
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("subscription Not create");
      }
    }
  );

  export const subsccriptionUpdate = createAsyncThunk(
    "subsccription/subsccriptionUpdate",
    async (formData, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
        };
        console.log(formData);
        const url = `${Baseurl}/api/v1/subscription/subscriptionupdate/${formData.subscriptionid}`;
        const resp = await axios.put(url, formData, config);
  
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("subscription Not create");
      }
    }
  );

  export const subscriptionDelete = createAsyncThunk(
    "subscription/subscriptionDelete",
    async (id, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
        };
        const url = `${Baseurl}/api/v1/subscription/subscriptiondelete/${id}`;
        const resp = await axios.delete(url, id, config);
        console.log(resp.data);
        const myreturn = {
          success: resp.data.success,
          id: id,
        };
        return myreturn;
      } catch (error) {
        return thunkAPI.rejectWithValue("subscription Not create");
      }
    }
  );

  export const subscriptionImages = createAsyncThunk(
    "subscription/subscriptionImages",
    async (formData, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
          maxBodyLength: Infinity,
        };
        const url = `${Baseurl}/api/v1/subscription/subscriptionimages`;
        const resp = await axios.post(url, formData, config);
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("subscription Not create");
      }
    }
  );

  export const validateSubscriptionSlugUrl = createAsyncThunk(
    "Subscription/validateSubscriptionSlugUrl",
    async (slugurl , thunkAPI) => {
      let resp = {
        success: false,
        message: "new slugurl",
      }
      try {
        const url = `${Baseurl}/api/v1/subscription/slugurl/${slugurl}`;
        const resp = await axios.get(url);
        return resp.data;
      } catch (error) {
        return error;
      }
    }
  );

  const SubscriptionSlice = createSlice({
    name: "subscriptions",
    initialState,
    reducers: {
      updateSubscriptionMobileImage(state, action) {
        state.mobileimage = action.payload;
        state.issubscriptionmobileimageLoading = false;
      },
      updateSubscriptionDeskimage(state, action) {
        state.desktopimage = action.payload;
        state.issubscriptiondeskimageLoading = false;
      },
      
    },
    extraReducers: {

      [getSubscription.pending]: (state) => {
        state.isLoading = true;
      },
      [getSubscription.fulfilled]: (state, action) => {
        state.subscriptiontotal = action.payload;
 
        state.isLoading = false;
        state.subscriptionLoading = false;
               localStorage.setItem("subscriptiontotal", JSON.stringify(state.subscriptiontotal ));
      },
      [getSubscription.rejected]: (state, action) => {
        state.isLoading = true;
      },
  
      [subscriptionPost.pending]: (state) => {
        state.subscriptionLoading = true;
      },
  
      [subscriptionPost.fulfilled]: (state, action) => {
        if (action.payload.success) {
          state.subscriptiontotal = [...state.subscriptiontotal, action.payload.subscription];
          state.mobileimage = "";
          state.desktopimage = "";
          state.issubscriptionmobileimageLoading = true;
          state.issubscriptiondeskimageLoading = true;
          localStorage.setItem("subscriptiontotal", JSON.stringify(state.subscriptiontotal ));
        }
        state.subscriptionLoading = false;
      },
  
      [subscriptionPost.rejected]: (state, action) => {
        state.subscriptionLoading = true;
      },
  


      [subsccriptionUpdate.pending]: (state) => {
        state.subscriptionLoading = true;
      },
  
      [subsccriptionUpdate.fulfilled]: (state, action) => {
        if (action.payload.success) {
          state.subscriptiontotal = state.subscriptiontotal.filter(
            (subscription) => subscription._id !== action.payload.subscription._id
          );
          state.subscriptiontotal = [...state.subscriptiontotal, action.payload.subscription];
          state.mobileimage = "";
          state.desktopimage = "";
          state.issubscriptionmobileimageLoading = true;
          state.issubscriptiondeskimageLoading = true;
          state.isLoading = !state.isLoading;
          localStorage.setItem("subscriptiontotal", JSON.stringify(state.subscriptiontotal ));
        }
        state.subscriptionLoading = false;
        
      },
  
      [subsccriptionUpdate.rejected]: (state, action) => {
        state.subscriptionLoading = true;
      },
  

      [subscriptionDelete.pending]: (state) => {
        state.delsubscriptionLoading = true;
      },
      [subscriptionDelete.fulfilled]: (state, action) => {
        if (action.payload.success) {
          state.subscriptiontotal = state.subscriptiontotal.filter(
            (subscription) => subscription._id !== action.payload.id
          );
          localStorage.setItem("subscriptiontotal", JSON.stringify(state.subscriptiontotal ));
        }
        state.delsubscriptionLoading = false;
      },
      [subscriptionDelete.rejected]: (state, action) => {
        state.delsubscriptionLoading = true;
      },


      [subscriptionImages.pending]: (state) => {
        state.issubscriptiondeskimageLoading = true;
      },
      [subscriptionImages.fulfilled]: (state, action) => {
        if (action.payload.success) {
          state.mobileimage = action.payload.mobileimages;
          state.desktopimage = action.payload.desktopImages;
  
        }
  
        state.issubscriptiondeskimageLoading = false;
        state.issubscriptionmobileimageLoading = false;
      },
      [subscriptionImages.rejected]: (state) => {
        state.issubscriptiondeskimageLoading = true;
      },
    },
  });
  export const {
    updateSubscriptionMobileImage,
    updateSubscriptionDeskimage
  } = SubscriptionSlice.actions;
  export default SubscriptionSlice.reducer;