

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  categorytotal: localStorage.getItem("categorytotal")
    ? JSON.parse(localStorage.getItem("categorytotal"))
    : [],

  mobileimage: "",
  desktopimage: "",
  isCatmobileImageLoading: true,
  isDeskImageLoading: true,
  isLoading: true,
  categoryLoading: true,
  deleteCatLoading: true,
  checkSlugurl: true,
};

export const getCategory = createAsyncThunk(
  "category/getCategory",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/category/all`;
      const resp = await axios(url);
      return resp.data.category;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const categoryPost = createAsyncThunk(
  "category/categorypost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/category/new`;
      const resp = await axios.post(url, formData, config);
      console.log(resp.data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("category Not create");
    }
  }
);

export const categoryMobileImage = createAsyncThunk(
  "category/categoryMobileImage",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/category/mobileimage`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("category Icon Not create");
    }
  }
);
export const categoryDesktopImage = createAsyncThunk(
  "category/categoryDesktopImage",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/category/desktopimage`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("category Icon Not create");
    }
  }
);
export const categoryImages = createAsyncThunk(
  "category/categoryImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/category/categoryimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("category Icon Not create");
    }
  }
);

export const validateSlugUrl = createAsyncThunk(
  "category/validateSlugUrl",
  async (slugurl, thunkAPI) => {
    let resp = {
      success: false,
      message: "new email",
    };
    try {
      const url = `${Baseurl}/api/v1/category/slugurl/${slugurl}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

export const categoryUpdate = createAsyncThunk(
  "category/categoryUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        // headers: {
        //   "Content-Type":
        //     "multipart/form-data; boundary=AaB03x" +
        //     "--AaB03x" +
        //     "Content-Disposition: file" +
        //     "Content-Type: png/jpg/jpeg" +
        //     "Content-Transfer-Encoding: binary" +
        //     "...data... " +
        //     "--AaB03x--",
        //   "Accept": "application/json",
        //   "type": "formData",
        // },
      };
      const url = `${Baseurl}/api/v1/category/${formData.catid}`;
      const resp = await axios.put(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("category Not create");
    }
  }
);
export const categoryDelete = createAsyncThunk(
  "category/categoryDelete",
  async (id, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/category/${id}`;
      const resp = await axios.delete(url, id, config);
      console.log(resp.data);
      const myreturn = {
        success: resp.data.success,
        id: id,
      };
      return myreturn;
    } catch (error) {
      return thunkAPI.rejectWithValue("category Not create");
    }
  }
);

const CategorySlice = createSlice({
  name: "categoryAll",
  initialState,
  reducers: {
    updateCatMobileImage(state, action) {
      state.mobileimage = action.payload;
      state.isCatmobileImageLoading = false;
    },
    updateCatDeskimage(state, action) {
      state.desktopimage = action.payload;
      state.isDeskImageLoading = false;
    },
    deleteCategory(state, action) {
      state.categorytotal = state.categorytotal
        .filter((category) => category._id !== action.payload);
    },
  },
  extraReducers: {
    [getCategory.pending]: (state) => {
      state.isLoading = true;
    },
    [getCategory.fulfilled]: (state, action) => {
      state.categorytotal = action.payload;
      localStorage.setItem("categorytotal", JSON.stringify(state.categorytotal));
      state.isLoading = false;
      state.categoryLoading = false;
    },
    [getCategory.rejected]: (state, action) => {
      state.isLoading = true;
    },

    [categoryPost.pending]: (state) => {
      state.categoryLoading = true;
    },

    [categoryPost.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.categorytotal = [
          ...state.categorytotal,
          action.payload.category,
        ].sort();
        localStorage.setItem(
          "categorytotal",
          JSON.stringify(state.categorytotal)
        );
        state.mobileimage = "";
        state.desktopimage = "";
        state.isCatmobileImageLoading = true;
        state.isDeskImageLoading = true;
      }
      state.categoryLoading = false;
      state.checkSlugurl = false;
    },

    [categoryPost.rejected]: (state, action) => {
      state.categoryLoading = true;
    },

    [categoryMobileImage.pending]: (state) => {
      state.isCatmobileImageLoading = true;
    },
    [categoryMobileImage.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.mobileimage = action.payload.mobileimages;
      }
      state.isCatmobileImageLoading = false;
    },

    [categoryMobileImage.rejected]: (state, action) => {
      state.isCatmobileImageLoading = true;
    },


    [categoryDesktopImage.pending]: (state) => {
      state.isDeskImageLoading = true;
    },
    [categoryDesktopImage.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.desktopimage = action.payload.desktopImages;
      }
      state.isDeskImageLoading = false;
    },

    [categoryDesktopImage.rejected]: (state, action) => {
      state.isDeskImageLoading = true;
    },
    [categoryImages.pending]: (state) => {
      state.isDeskImageLoading = true;
    },
    [categoryImages.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.desktopimage = action.payload.desktopImages;
        state.mobileimage = action.payload.mobileimages;
      }
      state.isDeskImageLoading = false;
      state.isCatmobileImageLoading = false;
    },

    [categoryImages.rejected]: (state, action) => {
      state.isDeskImageLoading = true;
    },

    [categoryUpdate.pending]: (state) => {
      state.categoryLoading = true;
    },

    [categoryUpdate.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.categorytotal = state.categorytotal.filter(
          (category) => category._id !== action.payload.category._id
        );
        state.categorytotal = [
          ...state.categorytotal,
          action.payload.category,
        ];
        state.mobileimage = "";
        state.desktopimage = "";
        state.isCatmobileImageLoading = true;
        state.isDeskImageLoading = true;
        localStorage.setItem(
          "categorytotal",
          JSON.stringify(state.categorytotal)
        );
      }

      state.categoryLoading = false;
    },

    [categoryUpdate.rejected]: (state, action) => {
      state.categoryLoading = true;
    },

    [categoryDelete.pending]: (state) => {
      state.deleteCatLoading = true;
    },

    [categoryDelete.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.categorytotal = state.categorytotal
          .filter((category) => category._id !== action.payload.id);
        localStorage.setItem(
          "categorytotal",
          JSON.stringify(state.categorytotal)
        );
      }
      state.deleteCatLoading = false;
    },

    [categoryDelete.rejected]: (state, action) => {
      state.deleteCatLoading = true;
    },
  },
});
export const {
  updateCatMobileImage,
  updateCatDeskimage,
  deleteCategory,
} = CategorySlice.actions;
export default CategorySlice.reducer;
