import "./App.css";
import { Route, Routes, HashRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import MainLayout from "./components/layout/MainLayout";
import Dashboard from "./pages/Dashboard";
import Error from "../src/pages/Error";
import Loginpage from "./pages/Loginpage";
import AddSuperCategory from "./pages/add-edit/superCategory/AddSuperCategory";
import ListSuperCategory from "./pages/add-edit/superCategory/ListSuperCategory";
import AddCategory from "./pages/add-edit/category/AddCategory";
import ListCategory from "./pages/add-edit/category/ListCategory";
import AddBrand from "./pages/add-edit/brand/AddBrand";
import ListBrand from "./pages/add-edit/brand/ListBrand";
import { getSuperCategory } from "./redux/superCategory/superCategorySlice";
import { getCategory } from "./redux/category/categorySlice";
import { getBrand } from "./redux/brand/brandSlice";
import { getSubCategory } from "./redux/subCategory/subCategorySlice";
import { getProduct } from "./redux/product/productSlice";
import AddSubCategory from "./pages/add-edit/subcategory/AddSubCategory";
import ListSubCategory from "./pages/add-edit/subcategory/ListSubCategory";
import UpdateCategory from "./pages/add-edit/category/UpdateCategory";
import UpdateSubCategory from "./pages/add-edit/subcategory/UpdateSubCategory";
import UpdateBrand from "./pages/add-edit/brand/UpdateBrand";
import UpdateSuperCategory from "./pages/add-edit/superCategory/UpdateSuperCategory";
import AddGrocery from "./pages/add-edit/grocery/AddGrocery";
import ListGrocery from "./pages/add-edit/grocery/ListGrocery";
import UpdateGrocery from "./pages/add-edit/grocery/UpdateGrocery";
import BookedOrder from "./pages/orders/BookedOrder";
import CancelledOrder from "./pages/orders/CancelledOrder";
import DeliveredOrder from "./pages/orders/DeliveredOrder";
import DispatchOrder from "./pages/orders/DispatchOrder";
import OutForDeliveryOrder from "./pages/orders/OutForDeliveryOrder";
import ProcessingOrder from "./pages/orders/ProcessingOrder";
import OrderDetails from "./pages/orders/OrderDetails";
import AssignDeliveryPersion from "./pages/orders/AssignDeliveryPersion";
import OrderInvoice from "./pages/orders/OrderInvoice";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const initialSetup = async () => {
      try {
        // getSuperCategory
        dispatch(getSuperCategory());

        // getCategory
        dispatch(getCategory());

        // getBrand
        dispatch(getBrand());

        // getSubCategory
        dispatch(getSubCategory());

      } catch (error) { }
    };
    initialSetup();
  }, []);





  return (
    <div className="wrapper">
      <HashRouter>
        <Routes>
          <Route path="/" element={<Loginpage />} />
          <Route path="/admin/order-invoice" element={<OrderInvoice />} />
          <Route path="/admin" exact element={<MainLayout />}>
            <Route index element={<Dashboard />} />

            {/* supercategory */}
            <Route
              path="/admin/addsupercategory"
              element={<AddSuperCategory />}
            />

            <Route
              path="/admin/listsupercategory"
              element={<ListSuperCategory />}
            />

            <Route
              path="/admin/updatesupercategory/:slugurl"
              element={<UpdateSuperCategory />}
            />

            {/* category */}
            <Route path="/admin/addcategory" element={<AddCategory />} />
            <Route path="/admin/listcategory" element={<ListCategory />} />
            <Route
              path="/admin/updatecategory/:slugurl"
              element={<UpdateCategory />}
            />

            {/* brand */}
            <Route path="/admin/addbrand" element={<AddBrand />} />
            <Route path="/admin/listbrand" element={<ListBrand />} />
            <Route
              path="/admin/updatebrand/:slugurl"
              element={<UpdateBrand />}
            />

            {/* subcategory */}
            <Route path="/admin/addsubcategory" element={<AddSubCategory />} />
            <Route
              path="/admin/listsubcategory"
              element={<ListSubCategory />}
            />
            <Route
              path="/admin/updatesubcategory/:slugurl"
              element={<UpdateSubCategory />}
            />

            {/* grocery */}
            <Route path="/admin/addgrocery" element={<AddGrocery />} />
            <Route path="/admin/listgrocery" element={<ListGrocery />} />
            <Route
              path="/admin/updategrocery/:slugurl"
              element={<UpdateGrocery />}
            />

            {/* order details */}
            <Route path="/admin/bookedorder" element={<BookedOrder />} />
            <Route
              path="/admin/orderdetails/:orderid"
              element={<OrderDetails />}
            />
            <Route
              path="/admin/assign-delivery/:orderid"
              element={<AssignDeliveryPersion />}
            />
            <Route path="/admin/cancelledorder" element={<CancelledOrder />} />
            <Route path="/admin/deliveredorder" element={<DeliveredOrder />} />
            <Route path="/admin/dispatchorder" element={<DispatchOrder />} />
            <Route
              path="/admin/outfordeliveryorder"
              element={<OutForDeliveryOrder />}
            />
            <Route
              path="/admin/processingorder"
              element={<ProcessingOrder />}
            />


          </Route>
          <Route path="*" element={<Error />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
