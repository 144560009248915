import React, { useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { MdOutlineRemove } from "react-icons/md";
import { useEffect } from "react";
import { Button } from "react-bootstrap";

import {
  groceryPost,
  groceryImages,
  validateGrocerySlugUrl,
} from "../../../redux/grocery/grocerySlice";

const AddGrocery = () => {
  const { superCatTotal } = useSelector((store) => store.superCategory);
  const { universaltag } = useSelector((store) => store.Tags);
  const { categorytag } = useSelector((store) => store.Tags);
  const { bannertag } = useSelector((store) => store.Tags);
  const { categorytotal } = useSelector((store) => store.categoryAll);
  const { subcategorytotal } = useSelector((store) => store.subCategories);
  const { brandtotal } = useSelector((store) => store.brand);
  const {
    mobileimage,
    desktopimage,
    mobileimageicon,
    desktopimageicon,
    isgrocerydeskimageLoading,
  } = useSelector((store) => store.groceries);
  const [productname, setProductname] = useState("");
  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [catbysuper, setCatbysuper] = useState([]);
  const [cattag, setCattag] = useState([]);
  const [categorytags, setCategorytags] = useState([]);
  const [unitag, setUnitag] = useState([]);
  const [universaltags, setUniversaltags] = useState([]);

  const [bantag, setBantag] = useState([]);
  const [bannertags, setBannertags] = useState([]);
  const [supercategory, setSupercategory] = useState("");
  const [supercategoryid, setSupercategoryid] = useState("");
  const [subCat, setSubcat] = useState([]);
  const [subcategory, setSubcategory] = useState("");
  const [subcategoryid, setSubcategoryid] = useState("");
  const [brand, setBrand] = useState("");
  const [catbrand, setCatbrand] = useState([]);
  const [brandbysupcat, setBrandbysupcat] = useState([]);
  const [slugUrl, setSlugUrl] = useState("");
  const [about, setAbout] = useState("");
  const [type, setType] = useState("");
  const [productoption, setProductoption] = useState("");
  const [ingredient, setIngredient] = useState("");
  const [rating, setRating] = useState("");
  const [productinfo, setProductinfo] = useState("");
  const [recommended, setRecommended] = useState(Boolean(0));
  const [multi, setMulti] = useState(Boolean(0));
  const [single, setSingle] = useState(Boolean(0));
  const [cashback, setCashback] = useState(Boolean(0));
  const [hotproducts, setHotproducts] = useState(Boolean(0));
  const [trending, setTrending] = useState(Boolean(0));
  const [offers, setOffers] = useState(Boolean(0));
  const [CostPrc, setCostPrc] = useState("");
  const [SellingPrice, setSellingPrice] = useState("");
  const [GstSellPrc, setGstSellPrc] = useState("");
  const [Mrp, setMrp] = useState("");
  const [metatitle, setMetatitle] = useState("");
  const [metakeyword, setMetakeyword] = useState("");
  const [metadesc, setMetadesc] = useState("");
  const [gstcost, setGstcost] = useState("");
  const [prime, setPrime] = useState(Boolean(0));
  const [packsizeoutofstock, setPacksizeoutofstock] = useState(Boolean(0));
  const [packsizeautoupdate, setPacksizeautoupdate] = useState(Boolean(0));
  const [error, setError] = useState(false);
  const [checkUrl, setCheckUrl] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");

  const dispatch = useDispatch();

  const selectuniversaltag = (evt) => {
    if (unitag.includes(evt.target.value)) {
      setUnitag(
        unitag.filter((e) => {
          return e !== evt.target.value;
        })
      );
    } else {
      setUnitag((selunitag) => [...selunitag, evt.target.value]);
    }
  };

  const selectbannertag = (evt) => {
    if (bantag.includes(evt.target.value)) {
      setBantag(
        bantag.filter((e) => {
          return e !== evt.target.value;
        })
      );
    } else {
      setBantag((selbantag) => [...selbantag, evt.target.value]);
    }
  };

  const selectcategorytag = (evt) => {
    if (cattag.includes(evt.target.value)) {
      setCattag(
        cattag.filter((e) => {
          return e !== evt.target.value;
        })
      );
    } else {
      setCattag((selcattag) => [...selcattag, evt.target.value]);
    }
  };

  useEffect(() => {
    const cattags = categorytag.filter((tag) => tag.CatId === categoryid);
    setCategorytags(cattags);
  }, [categoryid]);

  const verifyUrl = async (e) => {
    const value = e.target.value;
    setProductname(value);
    setCheckUrl("");
    const groceryUrl = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");
    setSlugUrl(groceryUrl);

    if (groceryUrl !== "") {
      let response = await dispatch(validateGrocerySlugUrl(groceryUrl));
      if (response.payload.success) {
        setError("Grocery Already exist");
        setErrorcolor("red");
      } else {
        setErrorcolor(" ");
        setError("");
        setCheckUrl(groceryUrl);
      }
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    if (checkUrl !== "") {
      const discount = Math.round(((Mrp - SellingPrice) * 100) / Mrp);
      const formData = {
        ItemName:
          productname.charAt(0).toUpperCase() +
          productname.slice(1).toLowerCase(),
        Url: slugUrl,
        superCategory: supercategory,
        superCategoryId: supercategoryid,
        Category: category,
        CatId: categoryid,
        SubCat: subcategory,
        SubCatId: subcategoryid,
        Brand: brand,
        ProductInfo: productinfo,
        About: about,
        Rating: rating,
        KeyWords: metakeyword,
        Description: metadesc,
        Title: metatitle,
        Recommends: recommended,
        Cashback: cashback,
        HotProducts: hotproducts,
        Trending: trending,
        Offers: offers,

        UniversalTag: unitag.join(", "),
        BannerTag: bantag.join(", "),
        CategoryTag: cattag.join(", "),

        ImgUrlMbl: mobileimage,
        ImgUrlMblIcon: mobileimageicon,
        ImgUrlDesk: desktopimage,
        ImgUrlDeskIcon: desktopimageicon,
        CostPrc: CostPrc,
        GstCost: gstcost,
        SellingPrice: SellingPrice,
        GstSellPrc: GstSellPrc,
        Mrp: Mrp,
        Discount: discount,
      };

      const data = await dispatch(groceryPost(formData));

      if (!data) {
        <div></div>;
      } else {
        if (data.payload.success) {
          alert("Grocery Added");
          setProductname("");
          setSupercategory(null);
          setSupercategoryid(null);
          setCategory(null);
          setCategoryid(null);
          setBrand(null);
          setSubcategory(null);
          setSubcategoryid(null);
          setSlugUrl("");
          setAbout("");
          setType("");
          setIngredient("");
          setRating("");
          setProductinfo("");
          setMetatitle("");
          setMetakeyword("");
          setMetadesc("");
          setCostPrc("");
          setSellingPrice("");
          setGstSellPrc("");
          setMrp("");
          setGstcost("");
          setRecommended(Boolean(0));
          setHotproducts(Boolean(0));
          setCashback(Boolean(0));
          setTrending(Boolean(0));
          setOffers(Boolean(0));
        } else {
          alert(data.message);
          console.log(data.error);
        }
      }
    } else {
      alert("please enter correct values");
    }
  };

  const selectsupCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSupercategoryid(value);
    setSupercategory(text);
  };
  const selectCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCategoryid(value);
    setCategory(text);
  };

  useEffect(() => {
    const catbysupercat = categorytotal.filter(
      (cat) => cat.superCategoryId === supercategoryid
    );
    setCatbysuper(catbysupercat);
  }, [supercategoryid, categorytotal]);

  const selectSubCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSubcategoryid(value);
    setSubcategory(text);
  };
  useEffect(() => {
    const subcatbycat = subcategorytotal.filter(
      (subcat) => subcat.categoryId === categoryid
    );
    setSubcat(subcatbycat);
  }, [categoryid, subcategorytotal]);

  const selectBrand = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setBrand(text);
  };

  useEffect(() => {
    // const brandbysupcat = brandtotal.filter(
    //   (brand) => brand.superCategoryId === supercategoryid
    // );
    const brandbysupcat = [...brandtotal].sort((a, b) =>
      a.name > b.name ? 1 : -1
    );
    setBrandbysupcat(brandbysupcat);
  }, [supercategoryid, brandtotal]);

  const imageChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(groceryImages({ desktopImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Add Product</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Super Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectsupCategory(e);
                        }}
                        value={superCatTotal._id}
                        name={superCatTotal.name}
                      >
                        <option value="0">{"Select Super Category"}</option>
                        {superCatTotal.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectCategory(e);
                        }}
                        value={catbysuper._id}
                        name={catbysuper.name}
                      >
                        <option value="0">{"Select Category"}</option>
                        {catbysuper.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Sub Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectSubCategory(e);
                        }}
                        value={subcategorytotal._id}
                        name={subcategorytotal.name}
                      >
                        <option value="0">{"Select Sub Category"}</option>
                        {subcategorytotal.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Brand *
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectBrand(e);
                        }}
                        value={brandbysupcat.name}
                        name={brandbysupcat.name}
                      >
                        <option value="0">{"Select Brand"}</option>
                        {brandbysupcat.map((data) => (
                          <option
                            key={data.name}
                            value={data.name}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">Name*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter  name"
                        required
                        value={productname}
                        onChange={(e) => verifyUrl(e)}
                      />
                      <p style={{ color: errorcolor }} className="mt-2">
                        {error}
                      </p>
                    </Col>
                    {/* <Col md>
                      <Form.Label htmlFor="disabledTextInput">Url*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter name"
                        required
                        value={slugUrl}
                        onChange={(e) => setSlugUrl(e.target.value)}
                      />
                    </Col> */}
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Product Info
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter  Product Info"
                        value={productinfo}
                        onChange={(e) => setProductinfo(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">About</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="about"
                        value={about}
                        onChange={(e) => setAbout(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Rating
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Rating"
                        value={rating}
                        onChange={(e) => setRating(e.target.value)}
                      />
                    </Col>
                  </Row>

                  <Row className="g-2  m-2">
                    <Col>
                      <Form.Label> Keywords </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Keyword"
                        style={{ height: "100px" }}
                        value={metakeyword}
                        onChange={(e) => setMetakeyword(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Descriptrion </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Descriptrion."
                        style={{ height: "100px" }}
                        value={metadesc}
                        onChange={(e) => setMetadesc(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Form.Label> Title </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Title."
                        style={{ height: "100px" }}
                        value={metatitle}
                        onChange={(e) => setMetatitle(e.target.value)}
                      />
                    </Col>
                  </Row>
                  {/* <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">Type</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter  Product Info"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Options
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="about"
                        value={productoption}
                        onChange={(e) => setProductoption(e.target.value)}
                      />
                    </Col>
                  </Row> */}
                  <Row className="g-2  m-2">
                    <Col>
                      <input
                        type="checkbox"
                        className="me-1"
                        value="recommended"
                        checked={recommended === true}
                        onChange={(e) => {
                          setRecommended(e.target.checked);
                        }}
                      />
                      <Form.Label>Recommends</Form.Label>
                    </Col>

                    <Col>
                      <input
                        type="checkbox"
                        className="me-1"
                        value="cashback"
                        checked={cashback === true}
                        onChange={(e) => {
                          setCashback(e.target.checked);
                        }}
                      />
                      <Form.Label>Cashback</Form.Label>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col>
                      <input
                        type="checkbox"
                        className="me-1"
                        value="hotproducts"
                        checked={hotproducts === true}
                        onChange={(e) => {
                          setHotproducts(e.target.checked);
                        }}
                      />
                      <Form.Label>Hot Products</Form.Label>
                    </Col>
                    <Col>
                      <input
                        type="checkbox"
                        className="me-1"
                        value="trending"
                        checked={trending === true}
                        onChange={(e) => {
                          setTrending(e.target.checked);
                        }}
                      />
                      <Form.Label>Trending</Form.Label>
                    </Col>

                    <Col>
                      <input
                        type="checkbox"
                        className="me-1"
                        value="offers"
                        checked={offers === true}
                        onChange={(e) => {
                          setOffers(e.target.checked);
                        }}
                      />
                      <Form.Label>Offer</Form.Label>
                    </Col>
                  </Row>

                  <hr color="black" />
                  <div className="categorytag">
                    <div>
                      <h5>Universal Tags</h5>
                      {universaltag.map((tag, index) => {
                        return (
                          <div key={index}>
                            <input
                              type="checkbox"
                              value={tag.name}
                              checked={unitag.includes(tag.name)}
                              onChange={(e) => {
                                selectuniversaltag(e);
                              }}
                            />
                            {tag.name}
                          </div>
                        );
                      })}
                    </div>
                    <div>
                      <h5>Category Tags</h5>
                      {categorytags.map((tag) => {
                        return (
                          <div key={tag._id}>
                            <input
                              type="checkbox"
                              value={tag.name}
                              checked={cattag.includes(tag.name)}
                              onChange={(e) => {
                                selectcategorytag(e);
                              }}
                            />
                            {tag.name}
                          </div>
                        );
                      })}
                    </div>

                    <div>
                      <h5>Banner Tags</h5>
                      {bannertag.map((tag, index) => {
                        return (
                          <div key={index}>
                            <input
                              type="checkbox"
                              value={tag.name}
                              checked={bantag.includes(tag.name)}
                              onChange={(e) => {
                                selectbannertag(e);
                              }}
                            />
                            {tag.name}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Product Image</Form.Label>
                      <Form.Control
                        type="file"
                        placeholder="No File Choosen"
                        onChange={imageChange}
                        multiple
                        required
                      />
                      <p>
                        * The image width and height should be 450px * 450px
                      </p>
                      <div>
                        {isgrocerydeskimageLoading ? (
                          <div></div>
                        ) : (
                          <img src={desktopimage} height={100} alt="img" />
                        )}
                      </div>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="gstcost">GST Cost</Form.Label>
                      <Form.Control
                        type="text"
                        value={gstcost}
                        required
                        onChange={(e) =>
                          setGstcost(e.target.value.replace(/\D/g, ""))
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Cost Prcice
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={CostPrc}
                        required
                        onChange={(e) =>
                          setCostPrc(e.target.value.replace(/\D/g, ""))
                        }
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Sell Price
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={SellingPrice}
                        required
                        onChange={(e) =>
                          setSellingPrice(e.target.value.replace(/\D/g, ""))
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        GST Sell Price
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={GstSellPrc}
                        required
                        onChange={(e) =>
                          setGstSellPrc(e.target.value.replace(/\D/g, ""))
                        }
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">MRP</Form.Label>
                      <Form.Control
                        type="text"
                        value={Mrp}
                        required
                        onChange={(e) =>
                          setMrp(e.target.value.replace(/\D/g, ""))
                        }
                      />
                    </Col>
                  </Row>

                  <div></div>
                </div>
                <div className="card-footer text-center">
                  {checkUrl !== "" &&
                  subcategoryid !== "" &&
                  categoryid !== "" &&
                  !isgrocerydeskimageLoading ? (
                    <button className="btn btn-danger" type="submit">
                      Add Product
                    </button>
                  ) : (
                    <button className="btn btn-danger" disabled type="submit">
                      Add Product
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddGrocery;
