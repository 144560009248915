import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { updateOrder } from "../../redux/order/orderSlice";
import { useParams, useNavigate } from "react-router-dom";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const AssignDeliveryPersion = () => {
  const { deliveryPersions } = useSelector((store) => store.Employees);

  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const [deliveryname, setDeliveryname] = useState("");
  const [deliveryMobile, setDeliveryMobile] = useState("");
  const [selectOrder, setSelectOrder] = useState("");

  useEffect(() => {
    const orderId = params.orderid;
    const fetchData = async () => {
      try {
        const url = `${Baseurl}/api/v1/order/singleorderbyclient/${orderId}`;
        const orderFetch = await axios.get(url);

        try {
          if (orderFetch.data.success) {
            const orderdetails = orderFetch.data.order;
            setSelectOrder(orderdetails);
          }
        } catch (error) {}
      } catch (error) {}
    };
    fetchData();
    setInterval(async () => {
      fetchData();
    }, 500);
  }, [params.orderid]);

  // useEffect(() => {
  //   const paramsorderId = params.orderid;
  //   const currentOrder = orders.find((order) => order._id === paramsorderId);
  //   setSelectOrder(currentOrder);
  // }, [params.orderid, orders]);

  const handlesubmit = async (e) => {
    e.preventDefault();

    var date = new Date();

    date.setDate(date.getDate());
    if (selectOrder.hasOwnProperty("OrderprocessDate")) {
      var OrderprocessDate = {
        OrderprocessingDate: selectOrder.OrderprocessDate.OrderprocessingDate,
        OrderDispatchedDate: selectOrder.OrderprocessDate.OrderDispatchedDate,
        OutfordeliveryDate: date,
        OrderDeliveredDate: selectOrder.OrderprocessDate.OrderDeliveredDate,
        OrderCancelledDate: selectOrder.OrderprocessDate.OrderCancelledDate,
      };
    } else {
      var OrderprocessDate = {};
    }

    if (deliveryMobile !== "" && deliveryname !== "") {
      const formData = {
        Status: 4,
        StatusText: "Out for Delivery",
        orderid: selectOrder._id,
        Delivery: {
          DName: deliveryname,
          DMobile: deliveryMobile,
        },
        remark: {
          ...selectOrder.remark,
          OutfordeliveryRemark: "Your item is out for delivery",
        },
        OrderprocessDate: OrderprocessDate,
      };
      console.log(formData, "formData");
      const data = await dispatch(updateOrder(formData));
      console.log(data, "data");
      if (!data) {
        <div></div>;
      } else {
        if (data.payload.success) {
          setDeliveryname("");
          setDeliveryMobile("");
          navigate("/admin/dispatchorder");
        } else {
        }
      }
    } else {
      alert("please enter correct values");
    }
  };

  const selectDeliveryPersion = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setDeliveryMobile(value);
    setDeliveryname(text);
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <div className="card me-2 ms-2">
                <div className="card-footer">
                  <h5>ASSINGN DELIVERY BOY & DISPATCH ORDER</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2 w-50">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        DELIVERY BOY
                      </Form.Label>

                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectDeliveryPersion(e);
                        }}
                        value={deliveryPersions.Dmobile}
                        name={deliveryPersions.Dname}
                      >
                        <option value="0">{"Select Delivery Boy"}</option>
                        {deliveryPersions.map((data, index) => (
                          <option
                            key={index}
                            value={data.Dmobile}
                            name={data.Dname}
                            required
                          >
                            {data.Dname}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                </div>
                <div className="card-footer ">
                  <button
                    className="btn btn-primary py-2 px-3"
                    type="submit"
                    style={{ background: "#556ee6", fontSize: "14px" }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AssignDeliveryPersion;
