import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import {
  groceryDelete,
  groceryUpdate,
} from "../../../redux/grocery/grocerySlice";
import BeatLoader from "react-spinners/BeatLoader";

import moment from "moment/moment";
import { Baseurl } from "../../../config/BaseUrl";
import axios from "axios";
import { Modal, ModalHeader } from "reactstrap";

const ListGrocery = () => {
  const dispatch = useDispatch();

  const { grocerytotal, groceryLoading, isgroceryLoading } = useSelector(
    (store) => store.groceries
  );

  const [productall, setProductall] = useState([]);
  const [search, setSearch] = useState("");
  const [filterproducts, setFilterProducts] = useState([]);
  const [temploading, setTempLoading] = useState(true);

  const [selectProduct, setSelectProduct] = useState("");
  const [cancelmodel, setCancelModel] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${Baseurl}/api/v1/grocery/all`;
      const fetchOrder = await axios.get(url);
      if (fetchOrder.data.success === true) {
        setTempLoading(false);
        const grocTotal = fetchOrder.data.grocery;
        const product = [...grocTotal].sort((a, b) =>
          a.ItemName > b.ItemName ? 1 : -1
        );
        setProductall(product);
      }
    };
    fetchData();
  }, []);

  // useEffect(() => {
  //   const product = [...grocerytotal].sort((a, b) =>
  //     a.ItemName > b.ItemName ? 1 : -1
  //   );
  //   setProductall(product);
  // }, [grocerytotal]);

  useEffect(() => {
    if (search !== "") {
      const result = productall.filter((product) => {
        return product.ItemName.toLowerCase().match(search.toLowerCase());
      });
      const reverlist = result.slice().reverse();
      setFilterProducts(reverlist);
    } else {
      const data = productall;
      setFilterProducts(data);
    }
  }, [search, productall]);

  const cancelClick = (e, grocery) => {
    setCancelModel(true);
    setSelectProduct(grocery);
  };

  const deleteClick = async (e, id) => {
    const updateprod = await dispatch(groceryDelete(selectProduct._id));

    if (updateprod.payload.success) {
      let updateProd = [];
      updateProd = filterproducts.filter((grocery) => grocery._id !== selectProduct._id);
      updateProd = [...updateProd];
      const product = [...updateProd].sort((a, b) =>
        a.ItemName > b.ItemName ? 1 : -1
      );
      setFilterProducts([...product]);
    }
  };
  const statusChange = async (e, data) => {
    e.preventDefault();
    console.log(data);
    const value = e.target.checked;

    const formData = {
      grocedeyid: data._id,
      OutOfStack: value,
    };
    const updateprod = await dispatch(groceryUpdate(formData));
    if (updateprod.payload.success) {
      let updateProd = [];
      updateProd = filterproducts.filter(
        (grocery) => grocery._id !== updateprod.payload.grocery._id
      );
      updateProd = [...updateProd, updateprod.payload.grocery];
      const product = [...updateProd].sort((a, b) =>
        a.ItemName > b.ItemName ? 1 : -1
      );
      setFilterProducts([...product]);
    }
  };
  const columns = [
    {
      field: "id",
      headerName: "S. No",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ItemName",
      headerName: "ITEM NAME",
      width: 200,
      // align: "center",
      headerAlign: "center",
    },
    // {
    //   field: "createdAt",
    //   headerName: "CREATED AT",
    //   width: 200,
    //   align: "center",
    //   headerAlign: "center",
    // },
    {
      field: "DATE",
      width: 150,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto">
            {moment(params.row.createdAt).format("MMM DD, YYYY")}
          </div>
        );
      },
    },
    {
      field: "OUT OF STOCK",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex m-auto">
              <Col>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=" "
                  value={params.row.OutOfStack}
                  checked={params.row.OutOfStack}
                  onChange={(e) => statusChange(e, params.row)}
                />
              </Col>
            </div>
          </>
        );
      },
    },

    {
      field: "ACTION",
      width: 190,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto my-3">
            <Link to={`/admin/updategrocery/${params.row.Url}`}>
              <Button variant="contained" color="primary">
                EDIT
              </Button>
            </Link>
          </div>
        );
      },
    },
    {
      field: "ACTION.",
      width: 190,
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <div className="d-flex m-auto">
            <Button
              variant="contained"
              onClick={(e) => cancelClick(e, cellValues.row)}
              color="error"
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Fragment>
        <div className="content-wrapper ">
          {temploading ? (
            <>
              <div className="d-flex justify-content-center loadingMain">
                <h4 style={{ fontWeight: "bold", color: "#36d7b7" }}>
                  Product is being fetched
                </h4>
                <BeatLoader
                  color={"#36d7b7"}
                  loading={temploading}
                  size={10}
                  className="mt-2 mx-2"
                />
              </div>
            </>
          ) : (
            <>
              <div className="card-body">
                <Row className="g-2  m-2 w-50">
                  <Col md>
                    <Form.Control
                      type="text"
                      placeholder="Search grocery products"
                      value={
                        search.charAt(0).toUpperCase() +
                        search.slice(1).toLowerCase()
                      }
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </Col>
                </Row>
              </div>

              <div
                style={{ height: 820, width: "100%" }}
                className="d-flex m-auto text-align-center"
              >
                <DataGrid
                  rows={filterproducts}
                  columns={columns}
                  getRowId={(row) => row._id}
                  pageSize={10}
                  rowHeight={50}
                  getRowHeight={() => "auto"}
                  rowsPerPageOptions={[5]}
                  // checkboxSelection
                />
              </div>

              <Modal
                size="md"
                isOpen={cancelmodel}
                toggle={() => setCancelModel(!cancelmodel)}
              >
                <ModalHeader>
                  <div className=" ">
                    <div className="col-12 ">
                      <div className="col-12">
                        <h3>
                          Do You Want to <span>Delete</span>{" "}
                          {selectProduct !== "" ? (
                            <>
                              <span
                                style={{
                                  color: "#dc3545",
                                }}
                              >
                                {selectProduct.ItemName}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}{" "}
                          Product
                        </h3>
                        <div className="col-12 mt-3 ">
                          <div className="col-6 d-flex justify-content-between">
                            <Button
                              variant="contained"
                              color="error"
                              className="mx-1 px-5"
                              onClick={() => setCancelModel(false)}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              className="mx-1 px-5"
                              onClick={(e) => deleteClick(e)}
                            >
                              Delete
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ModalHeader>
              </Modal>
            </>
          )}
        </div>
      </Fragment>
    </>
  );
};

export default ListGrocery;
